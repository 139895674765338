import $ from 'jquery';
import { AjaxPromise } from "./ajax_utilities";
import {debounce} from "lodash";
import {Toast} from "./toast";
import {preventEnterSubmittingForm, tableFilter} from "./common";

class OperatorSupplierList {
    PARENT_EL;
    DOM = {};
    TBL;

    constructor(el) {
        this.PARENT_EL = el;
        this.TBL = this.PARENT_EL.data('tbl');
        if(!this.TBL) {
            console.warn('Failed to init operator supplier list no tbl specified.');
            return;
        }

        this.setupDOM();
        this.setupEvents(true);

        $(`input#keyword_visibility_filter-opr_sup`)
            .on("keypress", (e) => preventEnterSubmittingForm(e))
            .on("keyup", debounce((e) => {
            tableFilter(e.currentTarget.value, {
                table: $(`#operator-suppliers-container table tbody`),
                filter_by_inputs: true,
                excluded_row_classes: ".template .fadeOut",
                handle_show_hide_callback: this.handleShowHideFiltering
            })
        }, 300));
    }

    setupDOM() {
        this.DOM.table = this.PARENT_EL.find('table');
        this.DOM.delete = this.DOM.table.find('.btn-js-delete');
        this.DOM.add = this.DOM.table.parent().find('.btn-js-add');

        // rows
        const $tr = this.DOM.table.find('tbody tr');

        this.DOM.tr = $tr.not('.template');
        this.DOM.tr_template = $tr.filter('.template');
        this.DOM.input_template = this.DOM.tr_template.find('input[type="text"]');
        this.DOM.inputs = this.DOM.table.find(`.supplier-input`);
        // use the custom event defined in form.js to construct the multiselect fields for the suppliers table
        window.dispatchEvent(new CustomEvent('rebuild-multiselects', {
            detail: {
                find:"select[multiple].opr_sup_assignable_to",
                changeFunction: debounce((e) => this.supplierInputChangeEvent(e, this.DOM), 500)
            }
        }));
    }

    setupEvents(init) {
        this.setupVisibilityFilters();

        // ADD (only ever going to be one add button so only need to set up event on page load)
        if (init) {
            this.DOM.add.on('click', async (e) => {
                await this.addSupplierAjax();
            });
        }

        this.DOM.table.find("tr.template input")
            .unbind('keypress')
            .on("keypress", (e) => preventEnterSubmittingForm(e));

        // UPDATE
        this.DOM.inputs.not("select[multiple]")
            .unbind('keyup change keypress')
            .on("keypress", (e) => preventEnterSubmittingForm(e))
            .on('keyup change', debounce((e) => this.supplierInputChangeEvent(e, this.DOM), 250));

        // DELETE logic is handled by operator_supplier_list_delete_modal.js so no need to add any delete logic here
    }

    async supplierInputChangeEvent(e, DOM) {
        const el = $(e.currentTarget),
            row = el.closest("tr").not('.template'),
            id = row.data('id'),
            name = el.attr('name'),
            newVal = el.val();

        // failed to find the row we want to update
        if (!row || row.length === 0) {
            return;
        }

        if (el.hasClass("needed") && (!newVal || newVal.length === 0 || (typeof newVal === "string" && newVal.trim().length === 0))) {
            if (name === "opr_sup_assignable_to[]") {
                el.parent().find("button.disable-exclude").addClass('error');
            } else {
                el.addClass('error');
            }
            return;
        } else {
            if (name === "opr_sup_assignable_to[]") {
                el.parent().find("button.disable-exclude").removeClass('error');
            } else {
                el.removeClass('error');
            }
        }

        // assignable to multiselect we need to check if they are unassigning a table the supplier is already linked to
        if (name === "opr_sup_assignable_to[]") {
            let openModal = false,
                additionalData = {
                    toUpdate: id,
                    name: name,
                    parentEl: row,
                };

            el.find("option").each((index, option) => {
                if (option.selected) {
                    return;
                }

                const cell = row.find(`td.col_${option.value}_count`),
                    count = parseInt(cell.attr("data-record_count"));

                if (!isNaN(count) && count > 0) {
                    openModal = true;
                    additionalData[`count_${option.value}`] = count;
                }
            });

            if (openModal) {
                // prevent showing the popup if there are issues with the fields in the row
                const serialized = this.serializeData(row);
                if (serialized.hasErrors) {
                    return;
                }
                app.MODAL_CORE.showModal("operator_supplier_list_update_modal", el, additionalData);
                return;
            }

        } else {
            // can only check against original value when not dealing with the multi select
            const val = row.find(`[name='${name}_original'].hidden-input`).val();
            if (val === newVal) {
                return;
            }
        }

        // UPDATE
        el.removeClass('error');
        const success = await this.updateSupplierAjax(id, name, DOM.table.find(`tr[data-id="${id}"]`));
        // can only update original value when not dealing with the multi select
        if (name !== "opr_sup_assignable_to[]") {
            if (row && row.length > 0 && success) {
                row.find(`[name='${name}_original'].hidden-input`).val(newVal);
            }
        }
    }

    async addSupplierAjax() {
        try {
            const serialized = this.serializeData();
            if (serialized.hasErrors) {
                return;
            }

            const data = { tbl: this.TBL, ...serialized.data };
            const res = await AjaxPromise({
                url: `${app.CACHE.URL_AJAX}opr_sup/add`,
                method: 'POST',
                data: data,
            });

            if(res.status !== 'success' || !res.data) {
                Toast.error(`An unexpected error occurred when creating new ${app.TBL[this.TBL].i}.`);
                console.warn('Unexpected AJAX Response', res);
                return;
            }

            this.addRow(res.data, data);
            // new row added ensure the delete button will have the delete event
            window.dispatchEvent(new CustomEvent('RebindModalButtons', {}))
            Toast.success(`${app.TBL[this.TBL].i} added successfully.`);
        } catch(err) {
            Toast.error(`An unexpected error occurred when creating new ${app.TBL[this.TBL].i}.`);
            console.warn('Unexpected AJAX Error', err);
        }
    }

    addRow(id, data) {
        let name = data.opr_sup_name,
            categoryCell = this.DOM.tr_template.find("select[name='opr_sup_category']").parents("td").clone(),
            templateAssignableTo = this.DOM.tr_template.find("#opr_sup_assignable_to"),
            assignableToSelect = templateAssignableTo.clone(),
            assignedTo = data['opr_sup_assignable_to[]'];

        let rowClasses = "";
        if (assignedTo.length > 0) {
            for (let i = 0; i < assignedTo.length; ++i) {
                rowClasses += (" supplier_row_" + assignedTo[i]);
            }
        }

        // set up a new row to contain the supplier the user just tried to add
        this.DOM.tr_template.after(`<tr class="${rowClasses}" data-id="${id}"></tr>`);

        // get the new row we just added
        let newRow = $(`#operator-suppliers-container tr[data-id='${id}']`);

        // add the supplier name table cell
        newRow.append(`
            <td class="keyword-filterable">
                <input type="hidden" class="hidden-input" name="opr_sup_name_original" value="${name}">
                <input type='text' class="supplier-input needed keyword-filterable" name="opr_sup_name" value='${name}'
                    placeholder="Enter Name of Supplier"
                />
            </td>
        `);

        // add the category select table cell
        newRow.append(categoryCell);
        newRow.find("select[name='opr_sup_category']").addClass("keyword-filterable").addClass("supplier-input").val(data.opr_sup_category)
            .closest("td").addClass(" keyword-filterable");

        // since the multi select uses a plugin we cannot straight copy the table cell.
        // instead we need to set up the empty table cell then append the specific select element into it.
        // We will then get the plugin to construct the multi select field.
        newRow.append(`
            <td class="multi-select-cell keyword-filterable">
                <div class="multiselect-container">
                    <label for="opr_sup_assignable_to" class="multiselect-label" style="display: none;">
                        <span class="multiple_count"></span>
                        <span class="multiple_count_total"></span>
                    </label>
                </div>
            </td>
        `);

        assignableToSelect.attr("id", `opr_sup_assignable_to_${id}`).val(assignedTo).removeClass('jqmsLoaded')
            .addClass(".supplier-input").addClass("keyword-filterable");
        newRow.find(".multi-select-cell div").append(assignableToSelect);

        // append the empty count cells and add in the remove button.
        newRow.append(`
            <td class="col_cost_count keyword-filterable" data-record_count="0">0</td>

            <td class="col_veh_count keyword-filterable" data-record_count="0">0</td>

            <td class="col_doc_count keyword-filterable" data-record_count="0">0</td>

            <td>
                <a id="list_${id}" data-modal="list_delete_modal"
                    title='Delete Company Supplier' data-text="this Company Supplier"
                    class="modal-open" href="/admin/ajax/opr_sup/delete"
                    data-supplier_id="${id}"
                    data-count_veh="0"
                    data-count_cos="0"
                    data-count_doc="0"
                >
                    <i class='btn-js-delete red fa fa-minus-circle'></i>
                </a>
            </td>
        `);

        // clear template rows input/select fields
        this.DOM.tr_template.find("input, select").not("select[multiple]").val('');
        window.dispatchEvent(new CustomEvent('ClearMultiSelect', {
            detail: {
                id:"opr_sup_assignable_to",
            }
        }));

        // get dom object
        this.setupDOM();
        this.setupEvents(false);
    }

    async updateSupplierAjax(toUpdate, name, parentEl) {
        try {
            const serialized = this.serializeData(parentEl);
            if (serialized.hasErrors) {
                return;
            }

            const data = { supplier_id: toUpdate, name: name, tbl: this.TBL, ...serialized.data };
            const res = await AjaxPromise({
                url: `${app.CACHE.URL_AJAX}opr_sup/edit`,
                method: 'POST',
                data: data,
            });

            if (res.status !== 'success') {
                Toast.error(`An unexpected error occurred when updating ${app.TBL[this.TBL].i}.`);
                return false;
            }

            // Dealing with the `assignable to` multi select we need to update the classes on the row.
            // This is so when the user next changes the visible rows this updated row will correctly be hidden/shown.
            if (name === "opr_sup_assignable_to[]") {
                const assignedTo = parentEl.find("select.opr_sup_assignable_to").val();
                // build a string containing the new classes for the row
                let rowClasses = "";
                if (assignedTo.length > 0) {
                    assignedTo.forEach(function (tbl) {
                        rowClasses += (" supplier_row_" + tbl);
                    });
                }
                // overwrite the old class list with the new one
                parentEl.prop("class", rowClasses);
            }

            Toast.success(`Changes saved.`);
            return true;
        } catch(err) {
            Toast.error(`An unexpected error occurred when updating ${app.TBL[this.TBL].i}.`);
            console.warn('Unexpected AJAX Error', err);
            return false;
        }
    }

    serializeData(parentEl = null) {
        let data = {};
        let hasErrors = false;
        if(!parentEl || parentEl.length === 0) {
            parentEl = this.DOM.tr_template;
        }

        $.each(parentEl.find('input:text, select'), (_k, el) => {
            el = $(el);
            let val = el.val();
            if (!val || val.length === 0 || (typeof val === "string" && val.trim().length === 0)) {
                if (el.hasClass('needed')) {
                    if (el.hasClass('opr_sup_assignable_to')) {
                        el.parent().find(".ms-options-wrap button.disable-exclude").addClass("error");
                    } else {
                        el.addClass('error');
                    }
                    hasErrors = true;
                }
                return;
            }

            if (el.hasClass('opr_sup_assignable_to')) {
                el.parent().find(".ms-options-wrap button.disable-exclude").removeClass("error");
            } else {
                el.removeClass('error');
            }

            if (el.attr('type') === 'checkbox' && !el.is(':checked')) {
                data[el.attr('name')] = '0';
                return;
            } else if (el.attr('type') === 'checkbox') {
                data[el.attr('name')] = '1';
                return;
            }

            data[el.attr('name')] = val;
        });

        return {
            hasErrors,
            data
        };
    }

    setupVisibilityFilters() {
        $(".opr_sup_table_filters input[type='checkbox'].show-hide-checkbox")
            .unbind("change", this.showHideSupplierRows)
            .on("change", this.showHideSupplierRows);
    }

    showHideSupplierRows() {
        const container = $(".opr_sup_table_filters"),
            checkboxCount = container.find("input[type='checkbox'].show-hide-checkbox").length,
            checkedBoxes = container.find("input[type='checkbox'].show-hide-checkbox:checked"),
            tableBody = $("#operator-suppliers-container table tbody"),
            keywordFilter = $(`input#keyword_visibility_filter-opr_sup`);

        // keyword filter is present we need to allow keyword filtering to handle the showing of the correct rows
        if (keywordFilter.length !== 0) {
            // no checkboxes are checked we reset to all boxes checked
            if (checkedBoxes.length === 0) {
                container.find("input[type='checkbox'].show-hide-checkbox").prop("checked", true);
            }

            keywordFilter.trigger("keyup");
            return;
        }

        // this logic below handles the showing/hiding of rows when the keyword filter is not present

        // no checkboxes are checked we reset to all boxes checked and all rows shown
        if (checkedBoxes.length === 0) {
            container.find("input[type='checkbox'].show-hide-checkbox").prop("checked", true);
            tableBody.find("tr").not(".fadeOut").show();
            return;
        }

        // all boxes are checked
        if (checkboxCount === checkedBoxes.length) {
            tableBody.find("tr").not(".fadeOut").show();
            return;
        }

        // hide all rows then we will re-show the rows associated with each checked box
        tableBody.find("tr").not(".template").not(".fadeOut").hide();
        checkedBoxes.each(function (index, checkbox) {
            tableBody.find("tr.supplier_row_" + checkbox.value).not(".fadeOut").show();
        });
    }

    handleShowHideFiltering(rows, show) {
        // need to hide the row(s) then we can do this safely
        if (!show) {
            rows.hide();
            return;
        }

        const container = $(".opr_sup_table_filters"),
            boxes = container.find("input[type='checkbox'].show-hide-checkbox"),
            checkboxCount = boxes.length,
            checkedBoxes = boxes.filter(":checked");

        // all boxes are checked we can safely show the row(s)
        if (checkboxCount === checkedBoxes.length) {
            rows.show();
            return;
        }

        // check each row to see if they can be visible with the current visibility filters selected
        rows.each(function(key, row){
            const rowObject = $(row);
            let showRow = false;
            boxes.each(function (index, checkbox) {
                // this row type can be visible then we can show it
                if (checkbox.checked && rowObject.hasClass(`supplier_row_${checkbox.value}`)) {
                    showRow = true;
                }
            });

            showRow ? rowObject.show() : rowObject.hide();
        });
    }
}

$(() => {
    app.DOM.content.find('#operator-suppliers-container').each((_k, el) => {
        const list = new OperatorSupplierList($(el));

        window.addEventListener('OperatorSupplierListUpdateAjax', async (e) => {
            const detail = e.detail;
            if (!detail.toUpdate || !detail.name || !detail.parentEl) {
                app.TOAST.error("Could not update the item at this time");
                return;
            }

            const row = detail.parentEl;
            const success = await list.updateSupplierAjax(detail.toUpdate, detail.name, detail.parentEl);
            if (success) {
                const deleteBtn = row.find("i.btn-js-delete").parent();
                row.find("select.opr_sup_assignable_to option").each((index, option) => {
                    if (option.selected) {
                        return;
                    }

                    const cell = row.find(`td.col_${option.value}_count`),
                        count = parseInt(cell.attr("data-record_count"));

                    if (!isNaN(count) && count > 0) {
                        cell.html(0);
                        cell.attr("data-record_count", 0);
                        deleteBtn.attr(`data-count_${option.value}`, 0);
                    }
                });

                app.MODAL_CORE.closeModal("operator_supplier_list_update_modal");
            }
        });
    });
});